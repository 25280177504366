import React from 'react'

const ElevenLogo = () => (
  <svg
    viewBox='0 0 216 81'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    preserveAspectRatio='xMidYMid'
    width='216'
    height='81'
  >
    <path fill="currentColor" d="M112 28.9077C113.012 28.9077 113.429 28.1935 113.429 27.4792C113.429 26.7649 113.012 26.0507 112 26.0507H100.453C99.7387 26.0507 99.0244 26.7054 99.0244 27.4792V50.2168C99.0244 50.931 99.6792 51.6453 100.453 51.6453H112C113.012 51.6453 113.429 50.931 113.429 50.2168C113.429 49.5025 113.012 48.7882 112 48.7882H101.881V39.8599H108.905C109.798 39.8599 110.334 39.1456 110.334 38.4313C110.334 37.7171 109.857 37.0028 108.905 37.0028H101.881V28.9077H112Z" fill="#111111"/>
    <path fill="currentColor" d="M132.238 48.7881H123.548V27.3005C123.548 26.4077 122.833 25.872 122.119 25.872C121.405 25.872 120.69 26.3482 120.69 27.3005V50.2167C120.69 50.9309 121.345 51.6452 122.119 51.6452H132.178C133.071 51.6452 133.607 50.9309 133.607 50.2167C133.607 49.5024 133.131 48.7881 132.238 48.7881Z" fill="#111111"/>
    <path fill="currentColor" d="M152.475 28.9077C153.487 28.9077 153.904 28.1935 153.904 27.4792C153.904 26.7649 153.487 26.0507 152.475 26.0507H140.928C140.214 26.0507 139.5 26.7054 139.5 27.4792V50.2168C139.5 50.931 140.154 51.6453 140.928 51.6453H152.475C153.487 51.6453 153.904 50.931 153.904 50.2168C153.904 49.5025 153.487 48.7882 152.475 48.7882H142.357V39.8599H149.38C150.273 39.8599 150.809 39.1456 150.809 38.4313C150.809 37.7171 150.333 37.0028 149.38 37.0028H142.357V28.9077H152.475Z"/>
    <path fill="currentColor" d="M173.249 25.9318C172.713 25.9318 172.118 26.289 171.939 27.0032L167.416 46.2885L162.892 27.0032C162.713 26.289 162.178 25.9318 161.582 25.9318C160.749 25.9318 160.154 26.527 160.154 27.3008C160.154 27.4199 160.154 27.5389 160.213 27.658L165.987 50.7527C166.166 51.4669 166.82 51.8241 167.475 51.8241C168.189 51.8241 168.785 51.4669 168.963 50.7527L174.737 27.658C174.796 27.4794 174.796 27.3008 174.796 27.3008C174.737 26.527 174.142 25.9318 173.249 25.9318Z"/>
    <path fill="currentColor" d="M193.963 28.9077C194.975 28.9077 195.392 28.1935 195.392 27.4792C195.392 26.7649 194.975 26.0507 193.963 26.0507H182.416C181.701 26.0507 180.987 26.7054 180.987 27.4792V50.2168C180.987 50.931 181.642 51.6453 182.416 51.6453H193.963C194.975 51.6453 195.392 50.931 195.392 50.2168C195.392 49.5025 194.975 48.7882 193.963 48.7882H183.844V39.8599H190.868C191.761 39.8599 192.296 39.1456 192.296 38.4313C192.296 37.7171 191.82 37.0028 190.868 37.0028H183.844V28.9077H193.963Z"/>
    <path fill="currentColor" d="M214.201 25.9318C213.486 25.9318 212.772 26.408 212.772 27.3604V45.3957L204.796 27.1223C204.379 26.1104 203.784 25.9318 202.891 25.9318C202.058 25.9318 201.225 26.3485 201.225 27.3604V50.4551C201.225 51.3479 201.939 51.8241 202.653 51.8241C203.368 51.8241 204.082 51.4074 204.082 50.4551V32.4198L212.117 50.5741C212.653 51.7646 213.486 51.7646 214.022 51.7646C214.855 51.7646 215.629 51.3479 215.629 50.1574V27.3008C215.57 26.408 214.915 25.9318 214.201 25.9318Z"/>
    <path fill="currentColor" d="M38.1921 0.277472C22.4187 1.28935 1.05012 12.7177 0.871552 37.1814C0.692985 58.3713 17.9545 74.4424 31.7637 73.6686C33.1922 73.6091 33.1922 74.0257 32.3589 74.74C29.2637 77.24 27.4185 76.9423 26.0495 77.1209C25.0972 77.2399 24.2043 77.8352 25.99 78.6685C29.2042 79.7994 46.5252 84.5612 64.8581 68.5497C77.9531 57.1214 83.1315 35.5743 73.4889 19.1461C65.3343 5.21783 50.6323 -0.49632 38.1921 0.277472ZM30.7518 23.5507C30.7518 24.6817 30.5137 28.0149 30.5732 29.2054C30.5732 29.622 30.6328 30.0982 30.6328 30.5744C30.6923 31.1101 30.6923 31.6458 30.6923 32.1815C30.6923 33.9076 30.6328 36.0504 30.5732 38.0147C30.5137 40.5146 30.6328 40.6932 30.6328 44.3241C30.6328 49.2644 30.5137 56.4071 30.5137 56.4071C30.4542 57.419 29.9185 58.7285 28.609 58.788C26.5257 58.9071 26.0495 57.3595 26.1091 56.169C26.1091 56.169 26.2281 48.5502 26.2281 43.3717C26.2281 41.4075 26.2281 39.6813 26.2281 37.479C26.2281 34.4433 26.3471 31.3482 26.2876 26.2292C26.2876 25.2769 25.99 22.5389 26.4067 20.6341C26.4067 20.6341 26.7638 18.908 28.8471 18.908C30.8113 18.908 30.7518 20.6341 30.7518 20.8127C30.8113 20.8722 30.7518 21.2294 30.7518 23.5507ZM53.4298 33.3719C53.4298 35.0981 53.3703 40.7527 53.3703 44.3241C53.3703 49.2644 53.2513 56.4071 53.2513 56.4071C53.1322 57.8952 52.6561 58.7285 51.3466 58.788C49.3228 58.9071 49.0847 57.538 49.0252 56.169C49.0252 56.169 48.7871 56.2881 48.7871 51.1691C48.7871 49.3239 48.9061 40.8122 48.9657 37.479C49.0252 34.3243 49.0252 34.86 49.0252 29.7411C49.0252 24.9793 49.2037 24.6817 49.2037 22.1817C49.2037 20.5746 49.6204 19.1461 51.1085 19.2056C53.0132 19.2056 53.5489 20.2175 53.6084 21.1103C53.6084 21.2294 53.6084 21.5865 53.6084 22.3008C53.6084 23.4317 53.6084 25.6935 53.6679 27.3006C53.6679 28.9078 53.4298 32.7767 53.4298 33.3719Z"/>
  </svg>

)

export default ElevenLogo