import { Link } from 'gatsby'
import React from 'react'
import Icon from './icons'
import { cn } from '../lib/helpers'

import styles from './nav.module.css'

const removeSpaces = (str) => str && str.replace(/\s/g, '');


const Nav = ({ onHideNav, onShowNav, showNav, companyInfo }) => {
  return(
    <div className={styles.root}>
      <div className={styles.wrapper}>

        <button aria-label="Navigation hamburger" className={cn(styles.toggleNavButton, showNav && styles.opened)} onClick={showNav ? onHideNav : onShowNav}>
          {/* <Icon symbol='hamburger' /> */}
          <div className={styles.hamburger}>
            <span className={styles.line}></span>
            <span className={styles.line}></span>
            <span className={styles.line}></span>
          </div>
        </button>

        <Link className={cn(styles.icon, styles.hideSmall)} to='/' aria-label="Home button">
          <Icon symbol='ElevenIcon' />
        </Link>
      </div>

        <div className={cn(showNav && styles.active, styles.backgroundClose)} onClick={showNav ? onHideNav : onShowNav}>
        </div>

        <MenuList companyInfo={companyInfo} showNav={showNav} />
    </div>
  )
}

const MenuList = ({ companyInfo, showNav }) => (
  <nav className={cn(styles.nav, showNav && styles.showNav) }>
    <ul>
      <li className={styles.welcomeLogo}>
        <Icon symbol='ElevenLogo' />
      </li>
      <li>
        <Link to='/'>
          <span>Home</span>
        </Link>
      </li>
      <li>
        <Link to='/work'>
          <span>Work</span>
        </Link>
      </li>
      <li>
        <Link to='/about'>
          <span>About</span>
        </Link>
      </li>
      <li>
        <Link to='/contact'>
          <span>Contact</span>
        </Link>
      </li>
      <li>
        <a href='https://www.livehire.com/widgets/job-listings/tbwa/public?multiSegment=true'>
          <span>Careers</span> 
          <Icon symbol='arrowTopLeft' />
        </a>
      </li>

      <li>
        <div className={`${styles.contactInfo}`}>
          <div className={`${styles.contactItem}`}>
            <h4>Auckland</h4>
            <p className={styles.addressItem} dangerouslySetInnerHTML={{ __html: companyInfo.auc_address }}></p>
            <a href={"mailto:"+companyInfo.auc_email}>{companyInfo.auc_email} </a>
            <br/>
            <a href={"tel:"+removeSpaces(companyInfo.auc_phone)} >{companyInfo.auc_phone}</a>
          </div>
          <div className={`${styles.contactItem}`}>
            <h4>Sydney</h4>
            <p className={styles.addressItem} dangerouslySetInnerHTML={{ __html: companyInfo.syd_address }}></p>
            <a href={"mailto:"+companyInfo.syd_email}>{companyInfo.syd_email} </a>
            <br/>
            <a href={"tel:"+removeSpaces(companyInfo.mel_phone)} >{companyInfo.mel_phone}</a>
          </div>

          <div className={`${styles.contactItem}`}>
            <h4>Melbourne</h4>
            <p className={styles.addressItem} dangerouslySetInnerHTML={{ __html: companyInfo.mel_address }}></p>
            <a href={"mailto:"+companyInfo.mel_email}>{companyInfo.mel_email} </a>
            <br/>
            <a href={"tel:"+removeSpaces(companyInfo.mel_phone)} >{companyInfo.mel_phone}</a>
          </div>


        </div>
      
      </li>
    </ul>
  </nav>
)






export default Nav
