import React from 'react'
import Icon from './icons'
import { spacing, responsiveParaLarge, responsiveParaSmall, responsiveParaXSmall } from '../components/typography.module.css'

import styles from './footer.module.css'
const removeSpaces = (str) => str && str.replace(/\s/g, '');

const Footer = ({companyInfo, siteInfo}) => {
  return(
    <footer className={styles.footer}>
      <div className={styles.footerWrapper}> 

        <div className={styles.infoBar}>
          <div className={`${styles.cell} ${spacing} ${responsiveParaLarge}`}>
            {siteInfo.footerMessage}
          </div>

          <div className={`${styles.cell} ${spacing}`}>
            <h4 className={responsiveParaXSmall}>Auckland</h4>
            <p className={styles.addressLine} dangerouslySetInnerHTML={{ __html: companyInfo.auc_address }}></p>
            <a href={"mailto:"+companyInfo.auc_email} className={responsiveParaXSmall}>{companyInfo.auc_email}</a>
            <br/>
            <a href={"tel:"+removeSpaces(companyInfo.auc_phone)} className={responsiveParaXSmall} >{companyInfo.auc_phone}</a>
          </div>

          <div className={`${styles.cell} ${spacing}`}>
            <h4 className={responsiveParaXSmall}>Sydney</h4>
            <p className={styles.addressLine} dangerouslySetInnerHTML={{ __html: companyInfo.syd_address }}></p>
            <a href={"mailto:"+companyInfo.syd_email} className={responsiveParaXSmall}>{companyInfo.syd_email}</a>
            <br/>
            <a href={"tel:"+removeSpaces(companyInfo.syd_phone)} className={responsiveParaXSmall} >{companyInfo.syd_phone}</a>
          </div>

          <div className={`${styles.cell} ${spacing}`}>
            <h4 className={responsiveParaXSmall}>Melbourne</h4>
            <p className={styles.addressLine} dangerouslySetInnerHTML={{ __html: companyInfo.mel_address }}></p>
            <a href={"mailto:"+companyInfo.mel_email} className={responsiveParaXSmall}>{companyInfo.mel_email}</a>
            <br/>
            <a href={"tel:"+removeSpaces(companyInfo.mel_phone)} className={responsiveParaXSmall} >{companyInfo.mel_phone}</a>
          </div>

        </div>


        <div className={`${styles.linkBar} ${spacing} `}>

          <span className={`${styles.cell} ${styles.logo} hideMed`}>
            <Icon symbol='ElevenLogo'/>
          </span>

          {companyInfo.social_instagram && (
            <a className={`${styles.cell} ${styles.social}`} href={companyInfo.social_instagram}>
              <span>Instagram</span>
            <Icon symbol='arrowTopLeft'/>
          </a>
          )}

          {companyInfo.social_facebook && (
            <a className={`${styles.cell} ${styles.social}`} href={companyInfo.social_facebook}>
              <span>Facebook</span>
            <Icon symbol='arrowTopLeft'/>
          </a>
          )}
          {companyInfo.social_linkedin && (
            <a className={`${styles.cell} ${styles.social}`}href={companyInfo.social_linkedin}>
              <span>Linkedin</span>
            <Icon symbol='arrowTopLeft'/>
          </a>
          )}

          <span className={`${styles.cell} ${styles.disclaimer} hideMed`} >
            {siteInfo.footerDisclaimer}
          </span>

        </div>
        <div className={`${styles.linkBar} hideOnLarge ${spacing}` }>
          <span className={`${styles.cell} ${styles.logo} `}>
            <Icon symbol='ElevenLogo'/>
          </span>
        <span className={`${styles.cell} ${styles.disclaimer}`} >
            {siteInfo.footerDisclaimer}
          </span>
        </div>

      </div>
    </footer>
  )
}

export default Footer
